<template>
  <v-container>
    <v-card elevation="2">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>About</v-toolbar-title><v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <ul>
          <li>
            <a
              href="https://documentcloud.adobe.com/link/acrobat/pdf-to-excel?x_api_client_id=adobe_com&x_api_client_location=pdf_to_excel"
              >Adobe PDF to XLS</a
            >
          </li>
          <li>
            <a
              href="https://forum.ironbutt.org/index.php?threads/rally-bonus-101.3677/"
              >Route planning link #1</a
            >
          </li>
          <li>
            <a
              href="https://hardtxrider.blogspot.com/2012/08/ride-planning.html"
              >Route planning link #2</a
            >
          </li>
          <li>
            <a
              href="https://hardtxrider.blogspot.com/2013/04/ld-riding-on-clock-organizing-framework.html"
              >Route planning link #3</a
            >
          </li>
          <li>
            <a href="http://www.ldriders.com/rally-routing-101.html"
              >Route planning link #4</a
            >
          </li>
          <li>
            <a
              href="https://www.buzzsprout.com/129999/2188085-episode-221-interview-with-iron-butt-rally-veteran-chris-comly?fbclid=IwAR2AuBjIEvI8AoLtC7ZCZ4VC5Er_Guo8WfaRjh-15tRD9a5_rLYlp_jdJBg"
              >Route planning link #5</a
            >
          </li>
          <li>
            <a href="http://www.gregrice.com/rallys/RallyPlanning.html"
              >Route planning link #6</a
            >
          </li>
          <li>
            <a
              href="https://forum.ironbutt.org/index.php?threads/my-basecamp-coding-method.741/"
              >Route planning link #7</a
            >
          </li>

          <li>
            <a href="http://turnlink.net/rally/">Route planning link #7</a>
          </li>
          <li>
            <a
              href="http://rallyrouting101.pbworks.com/w/page/24081670/Welcome%21"
              >Route planning link #7</a
            >
          </li>

          <li>
            <a
              href="https://forum.ironbutt.org/index.php?threads/bonus-preparation-spreadsheet-for-rallies.4614/"
              >Route planning link #8</a
            >
          </li>
        </ul>
      </v-card-text></v-card
    >
    <v-card class="mt-12">
      <v-card-title>Time restriction formatting</v-card-title>
      <v-card-text>
        <p>[day part]@[time part]|[day part]@[time part]</p>
        <p>
          [day part] = [] or [mon] or [mon-fri] or [mon,tue,fri] or [mon-wed,
          fri-sat]
        </p>
        <p>[time part] = [9A] or [7A-7P] or [7A-8A,4P-5P] or [8A,8P]</p>
        <p>
          [time part] = [9:00] or [7:00-19:00] or [7:00-8:00,16:00-17:00] or
          [8:00-20:00]
        </p>
        <p>SR = sunrise, SS = sunset</p>

        <p>
          Mon-Fri@7A-9P|Sat-Sun@9A-5P <br />
          [ { day_start: Mon day_end: Fri time_start: 7A time_end: 9P }, {
          day_start: Sat day_end: Sun time_start: 9A time_end: 5P } ]
        </p>
        <p>
          9A-9P <br />[ { day_start: Mon day_end: Sun time_start: 9A time_end:
          9P } ]
        </p>
        <p>
          Mon, Tue, Sat@9A <br />[ { day_start: Mon day_end: Mon time_start: 9A
          time_end: 9A }, { day_start: Tue day_end: Tue time_start: 9A time_end:
          9A }, { day_start: Sat day_end: Sat time_start: 9A time_end: 9A } ]
        </p>
        <p>
          Mon-Thu, Fri-Sat@8A-10A,5P-7P <br />[ { day_start: Mon day_end: Thu
          time_start: 8A time_end 10A }, { day_start: Mon day_end: Thu
          time_start: 5P time_end 7P }, { day_start: Fri day_end: Sat
          time_start: 8A time_end 10A }, { day_start: Fri day_end: Sat
          time_start: 5P time_end 7P } ]
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'About',
  data() {
    const data = {
      newBonus: { latitude: 44, longitude: 44 }
    }
    return { ...data }
  },
  computed: {},
  methods: {}
}
</script>
